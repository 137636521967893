.header-top {
  background-color: #7c0855;
  color: white !important;
}

.footer_sec {
  background-color: #7c0855;
  padding: 10px 20px;
}

.footer_sec>.row>.col {
  padding: 10px;
  text-align: center;
}

.significance {
  height: 200px;
  display: flex;
  align-items: center;
}

.significance>.m-auto>img {
  height: 5rem;
}

.significance-text {}

.bg-\[\#FFBABA\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 186 186 / var(--tw-bg-opacity));
}

.text-\[\#737373\] {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.font-medium {
  font-weight: 500;
}


.loader {
  border: 8px solid #f3f3f3;
  /* Light grey */
  border-top: 8px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin: 50px auto;
  /* Center the loader */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


@media (max-width: 425px) {
  .aiz-user-sidenav-wrap {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 275px;
    height: 100%;
    background-color: #fff;
    z-index: 1000;
    overflow-y: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }

  .aiz-user-sidenav-wrap.show {
    transform: translateX(0);
    /* position: absolute !important; */
  }

  .toggle-button {
    display: block;
    position: fixed;
    top: 15px;
    left: 15px;
    z-index: 111;
    color: #fff;
  }

  .z-1 {
    z-index: 111 !important;
  }

  .formresiter {
    width: 100%;
  }

  .mw-140 {
    height: 60px;
  }

  .mobile-hor-swipe {
    display: none;
  }


  .aiz-titlebar h1 {
    text-align: center;
  }

  .btn-primary {
    display: block;
  }

  .aiz-user-panel-2 {
    width: 100% !important;
  }

}

/* Hide the toggle button on larger screens */
@media (min-width: 426px) {
  .toggle-button {
    display: none;
  }

  .overlay {
    display: none !important;
  }

  .sticky-top {
    position: sticky;
    top: 104px;
    height: calc(100vh - 80px);
    overflow-y: auto;
  }





}

.z-111 {
  z-index: 111;
  /* width: 510px !important; */
}

.resister {
  background-image: url('../img/banner/ban-1.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 0px;

  /* background-size: 300px 100px; */
}

.rounded-2 {
  background: linear-gradient(90deg, rgba(169, 18, 176, 1) 0%, rgba(235, 36, 120, 0.8632703081232493) 100%);
}

.opacity-50 {
  color: #fff;
}

.flex-grow-2 {
  width: 100%;
}

.card .card-body {
  width: auto;
  overflow: overlay;
}

.aiz-user-panel-2 {
  width: 1100px;
}