.whatsapp-web {
    display: flex;
    height: 100vh;
}

.sidebar {
    width: 30%;
    background-color: #f8f9fa;
    border-right: 1px solid #ddd;
    display: flex;
    flex-direction: column;
}

.sidebar-header {
    padding: 10px;
    background-color: #ededed;
}

.search-bar {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 20px;
}

.chat-list {
    flex: 1;
    overflow-y: auto;
}

.chat-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
}

.avatar {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin-right: 10px;
}

.chat-details {
    flex: 1;
}

.chat-time {
    font-size: 12px;
    color: gray;
}

.chat-area {
    width: 70%;
    display: flex;
    flex-direction: column;
}

.chat-header {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #ededed;
}

.messages {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    background-color: #e5ddd5;
}

.message {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    max-width: 60%;
    padding: 10px;
    border-radius: 5px;
}

.message.sent {
    align-self: flex-end;
    background-color: #dcf8c6;
}

.message.received {
    align-self: flex-start;
    background-color: #fff;
}

.message span {
    align-self: flex-end;
    font-size: 10px;
    color: gray;
}

.chat-input {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #f8f9fa;
    border-top: 1px solid #ddd;
}

.chat-input input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
    margin-right: 10px;
}

.send-button {
    padding: 10px 20px;
    background-color: #34b7f1;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
}

.send-button:hover {
    background-color: #0e90d2;
}